<template>
  <div class="content_block">
    <div class="top">
      <b>营业执照</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini" @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <el-form
      :model="form"
      ref="JZForm"
      :rules="rules"
      label-position="right"
      label-width="100px"
      style="margin-top: 10px"
      v-if="isEditing"
    >
      <el-row>
        <el-col :span="8">
          <el-form-item label="成立日期" prop="establishDate">
            <el-date-picker
              v-model="form.establishDate"
              type="date"
              placeholder="请选择成立日期"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="营业有效期" prop="dueDate">
            <el-date-picker
              v-model="form.dueDate"
              type="date"
              :pickerOptions="pickerOptions"
              placeholder="请选择营业有效期"
              value-format="yyyy-MM-dd"
              style="width: 100%"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="营业执照">
            <Upload
              v-if="['1'].includes($store.state.company.currentCompanyInfo.authenticationStatus)"
              v-model="form.tenantFileReqDTOs"
              label="营业执照"
              type="single"
              buttonText="上传"
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              @success="handleFileLists"
              @remove="handleRemove"
              :maxSize="5"
              size="origin"
              :limit="1"
            />
            <ul v-else class="imgContainer">
              <li v-for="img in form.tenantFileReqDTOs" :key="img.url">
                <div class="left">
                  <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title="img.name">
                      <img :src="require('@/assets/pdf.svg')" />
                      {{ img.name }}
                    </span>
                  </el-link>
                </div>
                <div class="right">
                  <i class="el-icon-success" style="color: #67c23a; margin-right: 8px; font-size: 16px"></i>
                </div>
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-descriptions :colon="false" :column="2" style="margin-top: 10px" v-else>
      <el-descriptions-item label="成立日期" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.establishDate }}
      </el-descriptions-item>
      <el-descriptions-item label="营业有效期" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.dueDate }}
      </el-descriptions-item>
      <el-descriptions-item label="营业执照" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color: #ff5959; margin-right: 4px"></i>
          <el-link type="primary" :href="img.url" :underline="false" target="blank">{{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
import Upload from '@/components/common/upload';

export default {
  name: '',
  components: {
    Upload,
  },
  props: {
    certificateLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '6',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'BusinessLicence') {
          let certificateLists = JSON.parse(JSON.stringify(this.certificateLists));
          let info = certificateLists.find(i => i.type === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info
            ? {
              ...info,
              establishDate:
                  info && info.establishDate
                    ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD')
                    : '',
              dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
            }
            : {
              establishTime: '',
              dueDate: '',
              tenantFileReqDTOs: [],
              type: this.type,
            };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    certificateLists: {
      handler (val) {
        if (val && val.length) {
          let info = val.find(i => i.type === '6');
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info
            ? {
              ...info,
              establishDate:
                  info && info.establishDate
                    ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD')
                    : '',
              dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
            }
            : {
              establishTime: '',
              dueDate: '',
              tenantFileReqDTOs: [],
              type: this.type,
            };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(() => {
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.certificateLists.find(i => i.type === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info
        ? {
          ...info,
          establishDate:
              info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
          dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
        }
        : {
          establishTime: '',
          dueDate: '',
          tenantFileReqDTOs: [],
          type: this.type,
        };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i => ({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', { ...this.form, tenantFileReqDTOs }, this.type);
          this.isEditing = false;
        }
      });
    },
    handleDelete (index) {
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid => {
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i => ({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', { ...this.form, tenantFileReqDTOs }, this.type);
        }
      });
    },
    handleRemove (file) {
      const index = this.form.tenantFileReqDTOs.findIndex(v => v.uid === file.uid);
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
    handleFileLists (file) {
      this.form.tenantFileReqDTOs.push(file);
    },
  },
  data () {
    return {
      isEditing: false,
      rules: {
        tenantFileReqDTOs: [
          {
            required: true,
            message: '请选择营业执照',
            trigger: ['blur', 'change'],
          },
        ],
        establishTime: [
          {
            required: true,
            message: '请选择成立日期',
            trigger: ['blur', 'change'],
          },
        ],
        dueDate: [
          {
            required: true,
            message: '请选择营业有效期',
            trigger: ['blur', 'change'],
          },
        ],
      },
      form: {
        establishTime: '',
        dueDate: '',
        tenantFileReqDTOs: [],
        type: this.type,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '永久',
            onClick (picker) {
              const end = '9999-12-31';
              picker.$emit('pick', end);
            },
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.content_block {
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top {
    height: 40px;
    line-height: 40px;
    display: flex;
    .btnContainer {
      flex: 1;
      text-align: right;
    }
  }
}
/deep/ .warningLabel {
  width: 100px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929aa6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1f2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  .fileContainer {
    flex: 1;
  }
}
.imgContainer {
  list-style: none;
  li {
    height: 40px;
    line-height: 20px;
    background: #f5f7fa;
    border-radius: 4px;
    display: flex;
    width: 500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left {
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width: 440px;
        display: inline-block;
        span.el-link--inner {
          width: 100%;
          span.name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 420px;
            display: inline-block;
          }
        }
      }
    }
    img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
</style>
